import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { GeneralHttpService } from 'src/app/shared/services/general-http/general-http.service';

@Injectable({
  providedIn: 'root'
})
export class HeroViajesService {

  constructor(private http: GeneralHttpService) { }

  obtenerContinentes(): Observable<any> {
    return this.http.get(`api/v1/sura/viajes/continentes`)
  }

  obtenerCiudades(): Observable<any> {
    return this.http.get(`api/v1/sura/viajes/ciudades/57`)
  }

  tarifarViaje(data: any): Observable<any> {
    const codigoPartner = localStorage.getItem('partner');
    data["CodigoPartner"] = codigoPartner;
    return this.http.post(`api/v1/sura/viajes/tarifar`, data)
    // return this.http.json(`assets/json/coberturas-viajes.json`);
  }
}
