import {Component, OnInit, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import { FeatherModule } from 'angular-feather';

@Component({
    selector: 'app-bottom-sheet',
    templateUrl: './bottom-sheet.component.html',
    styleUrls: ['./bottom-sheet.component.scss'],
    standalone: true,
    imports: [FeatherModule]
})
export class BottomSheetComponent implements OnInit, OnChanges {
  @Output() closeBottomSheet = new EventEmitter<boolean>();
  @Input() isActive = true;
  @Input() id: string;
  @Input() desktop: string;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any): void {
    this.addActiveClass(changes.isActive.currentValue);
  }

  public addActiveClass(value): void {
    const bottomSheet = document.getElementById(this.id);

    if (value) {
      bottomSheet?.classList.add('active');
    } else {
      bottomSheet?.classList.remove('active');
    }
  }

  public closeModal(): void {
    this.closeBottomSheet.emit();
  }

}
