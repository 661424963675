import { Component, OnInit, Input } from '@angular/core';
import { Event, Router } from '@angular/router';
import { NgClass } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';


@Component({
    selector: 'app-item-product',
    templateUrl: './item-product.component.html',
    styleUrls: ['./item-product.component.scss'],
    standalone: true,
    imports: [MatIconModule, NgClass]
})
export class ItemProductComponent implements OnInit {
  @Input() label: string;
  @Input() urlIcon: string;
  @Input() color: string;
  @Input() textBlack = false;

  constructor(
    private router: Router
  ) {
    this.urlIcon = ''
   }

  ngOnInit(): void {
  }

  redirectOnboarding(event): void {
    event.stopPropagation();

    switch (this.label) {
      case 'Bici-Patineta':
        this.router.navigate(['/onboarding/bicicleta']);
        break;
      case 'Auto':
        this.router.navigate(['/onboarding/autos']);
        break;
      case 'Moto':
        this.router.navigate(['/onboarding/motos']);
        break;
      default:
        break;
    }
  }

}
