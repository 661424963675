<div class="item-product overflow-hidden mb-2 d-flex justify-content-between">
  <div class=" d-flex align-items-center p-1 justify-content-between w-100">
    <div class="d-flex align-items-center">
      <div class="me-3 d-flex justify-content-center align-items-center flex-shrink-0">
        <!-- <img [src]="urlIcon" [alt]="'Seguro de ' + label" height="40" width="40" class="img-fluid"> -->
        <mat-icon class="material-icons-outlined material-icon" [color]="color" >{{urlIcon}}</mat-icon>
      </div>
      <p class="item-product__wrapper m-0 fw-light fs-5" [ngClass]="{'text-black': textBlack}">{{label}}</p>
    </div>
    <!-- <i-feather class="item-product__icon-arrow mx-3" name="chevron-right"></i-feather> -->
  </div>
</div>