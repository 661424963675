import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralHttpService } from 'src/app/shared/services/general-http/general-http.service';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  constructor(private http: GeneralHttpService) { }

  obtenerProductos(): Observable<any> {
    return this.http.json(`assets/json/productos.json`);
    // return this.http.get(`api/v1/tucotizacion/Polizas`);
  }
}
